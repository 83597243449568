import { IJsonQuery } from '../../types/jsonQuery';
import { RedirectError, ResponseError } from '../../errors';
import { getBuilderIdFromJsonQuery, getIsSpecialPromoFromJsonQuery } from '../../utils/jsonQuery';
import { IApplicationContext } from '../../types/application';
import {
  fetchGetSpecialPromo,
  TGetSpecialPromoResponse_1,
  IGetSpecialPromoResponse200,
  IGetSpecialPromoResponse400,
} from '../../repositories/special-promos/v1/get-special-promo';

export const resolveSpecialPromo = async (
  context: IApplicationContext,
  jsonQuery: IJsonQuery,
): Promise<TGetSpecialPromoResponse_1 | null> => {
  const { httpApi, logger, config } = context;

  const isSpecialPromo = getIsSpecialPromoFromJsonQuery(jsonQuery);
  const builderId = getBuilderIdFromJsonQuery(jsonQuery);

  if (!isSpecialPromo || !builderId) {
    return null;
  }

  /**
   * @todo Удалить временный функционал
   * @description Данный функционал появился в задаче CD-234758, будет удалён в задаче CD-234853
   * При решении нужно будет принять решение, как поступить, удалить ли функционал ШСП с выдачи или всё же нет
   */
  {
    const marTechRedirectToShSPEnabled = !!config.get<boolean>('MarTech.RedirectToShSP.Enabled');

    if (marTechRedirectToShSPEnabled) {
      const redirectLocation = `/special-promos/${builderId}/`;

      if (process.env.IS_BROWSER_BUILD) {
        window.location.href = redirectLocation;

        return null;
      }

      throw new RedirectError({
        domain: 'services#resolveSpecialPromo',
        message: 'Переход на ШСП',
        location: redirectLocation,
        statusCode: 302,
      });
    }
  }

  try {
    const response = await fetchGetSpecialPromo<IGetSpecialPromoResponse200, IGetSpecialPromoResponse400>({
      httpApi,
      parameters: { builderId },
    });

    if (response.statusCode !== 200) {
      throw new ResponseError({
        domain: 'services#resolveSpecialPromo',
        message: response.response.message as string,
      });
    }

    return response.response as TGetSpecialPromoResponse_1;
  } catch (error) {
    logger.warning('[Degradation] Нет информации о спецпроекте, отображается обычная выдача ЖК', error);

    return null;
  }
};
